<template>
  <div class="backgroundOverride"></div>
  <h1 class="topCenter">About</h1>
  <br />
  <div class="wrapper overflow-auto">
    <p class="sentence_case">
      I'm not writing this in third person, as though I had my Personal
      Assistant type it up and run it through the Human Resources department.
    </p>
    <p class="sentence_case">This is me, Mark.</p>
    <p class="sentence_case">
      An international BIM (digital change) expert within the construction
      industry, hobbyist developer / opensource contributer, attendant to an
      amateur datacentre and cloud services, smart home / home automation
      implementer and baker of amazing sourdough pizza.
    </p>
    <p class="sentence_case">
      You may not have seen much of me, twitter is not my thing, but I spend
      every metric ounce of my working time (and substantially beyond that)
      focused on delivering tangible value for my clients, whether they are
      governments, contractors, manufacturers, clients, end users or students.
    </p>
    <p class="sentence_case">
      Because delivering value through smarter processes, better skills, and
      maximising technology is what I do; personally, professionally and
      permenantly.
    </p>
  </div>
</template>
<script>
export default {
  name: 'About',
}
</script>
<style scoped lang="scss">
.topCenter {
  margin: 0;

  @media screen and (min-width: 760px) {
    margin: 20vh 0 0 0;
  }
}

/*.backgroundOverride {
  @media screen and (max-width: 760px) {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 15%,
      rgba(255, 255, 255, 1) 20%
    ) !important;
  }
} */
</style>
